<template>
  <div>
    <h3>
      Park Alert Search
      <hr />
    </h3>

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label for="selectedLocation">Global/Park</label>
        <select
          class="form-control form-control-lg"
          id="selectedLocation"
          v-model="selectedLocation"
          @change="locationChanged"
          v-if="locations"
        >
          <option value="">Select Global/Park...</option>
          <option value="-1">Global</option>
          <option
            v-for="location in locations"
            :key="location.id"
            :value="location.id"
          >
            {{ location.longName }}
          </option>
        </select>
        <span
          v-else
          class="spinner-border spinner-border-sm mx-3"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>

    <button type="button" class="btn btn-primary mb-3 mt-4" @click="addAlert">
      ADD NEW PARK ALERT
      <i class="fa fa-plus-circle" />
    </button>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Park Alerts</div>
      </div>
      <div class="card-body" v-show="alerts.length > 0 && !loading">
        <div class="d-flex mb-2">
          <div class="align-self-center">Items Per Page:</div>
          <div class="ml-2">
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              aria-label="Items Per Page"
            ></b-form-select>
          </div>
        </div>
        <b-table
          ref="table"
          striped
          :fields="fields"
          :items="alerts"
          :current-page="currentPage"
          :per-page="perPage"
          stacked="md"
          small
          class="mt-4"
          bordered
          sort-icon-left
        >
          <template v-slot:cell(associatedSpots)="data">
            <div v-for="(spot, index) in data.item.alertSpots" :key="spot.id">
              <div v-if="index < 5">
                {{ spot.locationName }} - {{ spot.spotLoopName }} -
                {{ spot.spotName }}
              </div>

              <b-collapse :id="'spots' + data.item.id" v-if="index >= 5">
                {{ spot.locationName }} - {{ spot.spotLoopName }} -
                {{ spot.spotName }}
                <br />
              </b-collapse>
              <b-button
                v-if="
                  data.item.alertSpots.length > 5 &&
                    data.item.alertSpots.length - 1 == index
                "
                v-b-toggle="'spots' + data.item.id"
                variant="link"
                ><span class="when-open text-primary">Show less</span
                ><span class="when-closed text-primary"
                  >Show more...</span
                ></b-button
              >
            </div>
          </template>
          <template v-slot:cell(id)="data">
            <button
              type="buttom"
              @click="preview(data.item)"
              class="btn btn-primary btn-sm mr-2 my-1"
            >
              Preview
            </button>
            <button
              class="btn btn-primary btn-sm mr-2 my-1"
              @click="modify(data.item.id)"
              :disabled="areActionButtonsDisabled(data.item)"
            >
              Modify
            </button>
            <button
              class="btn btn-primary btn-sm mr-2 my-1"
              @click="deleteAlert(data.item)"
              :disabled="areActionButtonsDisabled(data.item)"
            >
              Delete
            </button>
          </template>
        </b-table>
        <div class="d-flex">
          <div class="mr-auto">
            Showing
            {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
            {{ perPageDisplay }} of {{ totalRows }} entries
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
      <div class="card-body text-center" v-if="alerts.length === 0 && !loading">
        <div class="h4 my-3" v-if="this.selectedLocation == -1">
          Currently no global alerts.
        </div>
        <div class="h4 my-3" v-else>Currently no alerts for this park.</div>
      </div>
      <div class="card-body text-center" v-if="loading">
        <div class="my-3">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </div>
    </div>
    <b-modal id="deleteModal" title="Delete Alert" @ok="confirmDelete">
      <div class="font-weight-bold">
        Are you sure you want to delete this alert?
      </div>

      <div>
        {{ alertToDelete.startDate | formatDateAsUTCDate }} -
        {{ alertToDelete.endDate | formatDateAsUTCDate }}
      </div>
      <br />
      <div class="font-weight-bold">Alert Type:</div>
      <div>{{ alertToDelete.alertTypeName }}</div>
      <br />
      <div>
        <button
          type="buttom"
          @click="preview(alertToDelete)"
          class="btn btn-primary btn-sm"
        >
          Preview Alert
        </button>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button type="buttom" @click="ok()" class="btn btn-primary">
          Confirm Delete
        </button>
        <button type="buttom" @click="cancel()" class="btn">Cancel</button>
      </template>
    </b-modal>
    <AdminHtmlPreviewModal
      title="Alert Preview"
      :content="previewContent"
      ref="previewModal"
    />
  </div>
</template>

<script>
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import AdminAlertService from "@/services/admin/AdminAlertService.js";
import AdminHtmlPreviewModal from "@/components/admin/AdminHtmlPreviewModal";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminAlertSearch",
  title: "Admin - Alert Search",
  mixins: [checkPermissionMixin],
  components: {
    AdminHtmlPreviewModal
  },
  data() {
    return {
      previewContent: "",
      selectedLocation: "",
      locations: null,
      loading: false,
      alerts: [],
      alertToDelete: {},
      showMoreId: null,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        {
          key: "referenceId",
          label: "Reference Id",
          sortable: true
        },
        {
          key: "startDate",
          label: "From Date",
          sortable: true,
          formatter: "formatDate"
        },
        {
          key: "endDate",
          label: "To Date",
          sortable: true,
          formatter: "formatDate"
        },
        { key: "alertTypeName", label: "Alert Type", sortable: true },
        { key: "associatedSpots", sortable: false },
        { key: "id", sortable: false, label: "Actions/Options" }
      ]
    };
  },
  computed: {
    totalRows() {
      return this.alerts.length;
    },
    perPageDisplay() {
      if (this.perPage * this.currentPage > this.totalRows) {
        return this.totalRows;
      }
      if (this.totalRows > this.perPage) {
        return this.perPage;
      }
      return this.totalRows;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    areActionButtonsDisabled(alert) {
      if (this.selectedLocation == -1) {
        return !this.checkPermission("ParkManagementParkAlertsGlobal");
      }

      const locationIds = alert.alertSpots.map(n => n.locationId);
      const disabled = !this.checkLocationsPermission(
        "ParkManagementParkAlertsParkSpot",
        locationIds
      );
      return disabled;
    },
    confirmDelete() {
      const alertService = new AdminAlertService(this.tenantId);
      alertService.deleteAlert(this.alertToDelete.id).then(response => {
        if (response?.statusCode === "Success") {
          this.getAlerts();
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Park alert has been deleted",
            layer: "admin"
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    addAlert() {
      this.$router.push("/admin/park-alert-add").catch(() => {});
    },
    setShowMore(id) {
      this.showMoreId = id;
    },
    formatDate(date) {
      return this.$options.filters.formatDateAsUTCDate(date);
    },
    associatedSpots(data) {
      data.item;
    },
    locationChanged() {
      this.$store.commit("admin/setAlertSearchLocation", this.selectedLocation);
      this.getAlerts();
      this.currentPage = 1;
    },
    deleteAlert(item) {
      this.alertToDelete = item;
      this.$bvModal.show("deleteModal");
    },
    modify(id) {
      this.$router.push(`/admin/park-alert-modify/${id}`).catch(() => {});
    },
    preview(alert) {
      this.previewContent = alert.message;
      this.$refs.previewModal.show();
    },
    async getLocations() {
      const locationService = new AdminLocationService(this.tenantId, null);
      const response = await locationService.getLocations(this.tenantId);
      this.locations = response.data.filter(loc =>
        this.checkLocationPermission("ParkManagementParkAlertsParkSpot", loc.id)
      );
      this.loadingParks = false;
      const selectedLocation = this.$store.getters["admin/alertSearchLocation"];
      if (selectedLocation) {
        this.selectedLocation = selectedLocation;
        this.getAlerts();
      }
    },
    async getAlerts() {
      if (!this.selectedLocation) {
        return;
      }
      this.loading = true;
      const alertService = new AdminAlertService(this.tenantId);
      const response = await alertService.getAlerts(this.selectedLocation);
      this.alerts = response.data.map(x => {
        return { ...x, referenceId: `A${(x.id + "").padStart(4, "0")}` };
      });
      this.loading = false;
    }
  },
  created() {
    this.getLocations();
  }
};
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.modal-title {
  font-weight: bold;
}
</style>
